























import { defineComponent, onMounted, reactive } from '@vue/composition-api'

export default defineComponent({
  setup() {
    const state = reactive({
      challenge: {
        a: 0,
        b: 0,
        operation: '+'
      }
    })

    const model = reactive({
      email: '',
      challenge: ''
    })

    const newChallenge = () => {
      state.challenge.a = Math.floor(Math.random() * 10)
      state.challenge.b = Math.floor(Math.random() * 10)

      model.challenge = ''
    }

    const onSubmit = () => {
      newChallenge()
    }

    onMounted(() => {
      newChallenge()
    })

    return { state, model, onSubmit }
  }
})
